/* Importing a funky font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&family=Orbitron:wght@400;500&display=swap');

/* Global Styles */
body {
  font-family: 'Poppins', sans-serif; /* Using funky Poppins font */
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  color: #333;
  overflow-x: hidden;
  transition: all 0.3s ease;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
}

/* Navbar Styles */
.navbar {
  background-color: #111;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: navbar-slide 1s ease-in-out;
  flex-wrap: wrap;
}

.navbar .logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.navbar .logo img:hover {
  transform: scale(1.1);
}

.navbar nav ul {
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  justify-content: center;
}

.navbar nav ul li {
  margin-left: 20px;
}

.navbar nav ul li button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.navbar nav ul li button:hover {
  color: #ff6347;
  transform: scale(1.1);
}

@keyframes navbar-slide {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Center Content Styles */
/* Center Content Styles */
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px); /* Adjusting to ensure proper centering */
  animation: fadeIn 1.5s ease-in;
  flex-direction: column; /* Adjust to stack items vertically */
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
  margin-bottom: 120px;
}

.center-image img {
  width: 250px; /* Increased image size */
  height: 250px;
 
  margin-right: 50px; /* Increased margin to create more distance */
  transition: transform 0.3s ease;

}

.center-image img:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.center-text {
  max-width: 600px;
  text-align: left;
  opacity: 0;
  animation: text-fadeIn 1s ease-in forwards;
  margin-left: 40px; /* Added margin to give more breathing space */
}

.center-text .title {
  font-size: 4.5rem; /* Increased font size for the title */
  font-weight: 700;
  color: #960c0c;
  font-family: 'Orbitron', sans-serif;
  text-shadow: 0 0 10px #ff6347, 0 0 20px #ff6347, 0 0 30px #ff6347; /* Glowing effect */
  transition: color 0.3s ease;
}

.center-text .subtitle {
  font-size: 2.2rem;
  color: #ff6347; /* Slightly brighter subtitle color */
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}

.center-text .goal {
  font-size: 1.5rem;
  color: #660fca;
  margin-top: 15px;
}

.center-text .cta {
  font-size: 2rem;
  color: #ff6347;
  margin-top: 25px;
}

.center-text .buttons {
  display: flex;
  gap: 25px;
  margin-top: 30px;
}

.center-text .buttons .btn,
.center-text .buttons .roadmap-btn {
  padding: 14px 30px;
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.center-text .buttons .btn:hover,
.center-text .buttons .roadmap-btn:hover {
  background-color: #e53e3e;
  transform: translateY(-5px);
}

.center-text .buttons .roadmap-btn {
  background-color: #333;
  color: #ff6347;
}

.center-text .buttons .roadmap-btn:hover {
  background-color: #444;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes text-fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Footer Styles */
/* Footer Styles */
.footer {
  text-align: center;
  padding: 0px 20px; /* Reduced padding for larger screens */
  background-color: #333;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer p {
  margin: 10px 0;
  font-size: 1rem;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.footer-icon {
  width: 30px; /* Set size of the emoji */
  height: 30px;
  transition: transform 0.3s ease;
}

.footer-icon:hover {
  transform: scale(1.2); /* Add hover effect to enlarge the icon */
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .footer {
    padding: 10px; /* Smaller padding on mobile */
  }

  .footer p {
    font-size: 0.9rem;
  }

  .footer-icons {
    gap: 15px;
  }

  .footer-icon {
    width: 25px;
    height: 25px;
  }
}

/* Coin Flip Game Styles */
.coin-flip-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1.5s ease-in;
  padding: 20px;
}

.coin-flip-container h2 {
  font-size: 2rem;
  color: #333;
}

.coin-flip-container .choices button {
  margin: 10px;
  padding: 12px 25px;
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.coin-flip-container .choices button:hover {
  background-color: #e53e3e;
  transform: scale(1.05);
}

.coin-flip-container .choices button:active {
  transform: scale(0.95);
}

.coin-flip-container h3 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
}

.coin-flip-container p {
  font-size: 1.2rem;
  color: #ff6347;
  margin-top: 10px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes text-fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 10px;
    flex-direction: column;
    text-align: center;
  }

  .navbar .logo img {
    display: none; /* Hide logo on mobile */
  }

  .navbar nav ul {
    flex-direction: column;
    margin-top: 10px;
  }

  .navbar nav ul li {
    margin: 10px 0;
  }

  .center-content {
    height: auto;
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }

  .center-image img {
    display: none; /* Hide side image on mobile */
  }

  .center-text {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .center-text .title {
    font-size: 2.5rem;
    margin-top: 180px;
  }

  .center-text .buttons {
    margin-left: 120px;
  }

  .center-text .subtitle {
    font-size: 1.2rem;
  }

  .center-text .cta {
    font-size: 1.4rem;
  }

  .coin-flip-container {
    padding: 20px;
  }

  .coin-flip-container h2 {
    font-size: 1.8rem;
  }

  .footer {
    padding: 10px;
  }

  .footer p {
    font-size: 0.9rem;
  }

  .footer-icons {
    gap: 15px;
  }

  .footer-icon {
    width: 25px;
    height: 25px;
  }
}
